.border { border: 1px solid #333; }

@font-face {
    font-family: 'Lato Light', Helvetica, Arial, 'Open Sans', sans-serif;
    src: url('../fonts/lato/lato.light.ttf') format('truetype'); 
}
body {
    margin: 0;
    font-size: 11pt;
    color: #555555;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
img { border-width: 0px; }
div, button, span, td, th,
input[type="text"],
input[type="password"],
textarea, select {
    font-family: 'Lato Light', Helvetica, Arial, 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
div, span, td, th { 
    word-wrap: break-word;
    white-space: pre-line;
}
a { text-decoration:none; color: #007BFF; }
a.link, a.visited, a.active, a.hover { text-decoration:none; color: #007BFF; }

*:focus { outline: none; }

h1{ font-weight: 900; }

.cursor-on { cursor: pointer; }
.noScroll { overflow: hidden;}
.scroll-y { overflow-x: hidden; overflow-y: auto;}

.main-container { width: 100%; padding: 0px; }
.flex-container { display:flex; }
.divHide { display : none; }
.divRight {
    margin: 0;
    padding: 0;
    display: flex; justify-content: flex-end;
}
.divMiddle, .divCenter, .center-screen {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.divCenter, .center-screen { 
    align-items: center;
}
.center-screen { 
    min-height: 100vh;
}

.w100p { width:100%; }
.alignLeft { text-align : left; }
.alignRight{ text-align: right; }
.alignCenter{ text-align: center; }
.mt-05{ margin-top: 5px; }
.mt-10{ margin-top:10px; }
.mt-20{ margin-top:20px; }
.mt-30{ margin-top:30px; }
.mt-50{ margin-top:50px; }
.mb-20{ margin-bottom:20px; }
.mb-30{ margin-bottom:30px; }
.mr-20{ margin-right:20px; }
.mr-30{ margin-right:30px; }
.ml-10{ margin-left: 10px; }
.ml-20{ margin-left: 20px; }
.ml-30 { margin-left: 30px; }

.group-1{ padding:20px 20px 20px 20px; }
.group-2{ padding:20px 0; }
.padRLTB-10{ padding: 10px; }
.padRL-0{ padding: 0px 0px 0px 0px; }
.padRL-10{ padding: 0px 10px 0px 10px;}
.padRL-15{ padding: 0px 15px 0px 15px; }
.padLeft-30{ padding-left: 30px; }
.padRight-30{ padding-right: 30px; }
.padTop-20{ padding-top: 20px; }
.btn-blue{
    background-color: #1e73be;
    color:#FFFFFF;
}
.btn-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.header{
    background-color: #1e73be;
    color:#FFFFFF;
    padding:20px;
    width:100%;
    margin:0px;
    box-shadow: 1px 1px 5px #666;
}
.footer{
    background-color: #1e73be;
}
.colfooter{
    color:#FFFFFF;
    text-align: justify;
    font-size:85%;
    width:480px;
}
.copyright{
    background-color: #666666;
    color:#FFFFFF;
    text-align: center;
    font-size:75%;
    padding:10px;
    margin:0px 0px 0px 0px;
}
.subTitle{
    background-color: #666666;
    border-style: none;
    padding: 15px 15px 15px 15px;
    color:#FFFFFF;
    font-size: 17px;
    border-radius:5px;
}
.note{
    padding-top:5px;
    font-size:75%;
    color:#666666;
}
.errText {
    font-size: 9.5pt;
    color: #DC3545;
    width: 100%;
}
.main-content {
    position: relative;
    flex: auto;
    margin: 50px;
    padding: 0px;
    background-size: cover;
    transition: 0.3s;
}
.flexrow{
    display: flex;
    flex-flow: row wrap;
}
.flexcol{
    padding-top:20px;
    padding-right: 100px;
    display: flex;
    flex-flow: column;
}
.btn-blue{
    background-color: #1e73be;
    color:#FFFFFF;
}
ul.style1 {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
/* ---- CARD SHADOW ---------------------------------------- */
.divCard {
  border: 0px solid;
  padding: 10px;
  /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
/* ---- LOADING ---------------------------------------- */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
}
  
.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.5) -1.5em 0 0 0, rgba(180, 180, 180, 0.5) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) -1.5em 0 0 0, rgba(180, 180, 180, 0.75) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
